/*global ajax_object*/

// Import everything from autoload folder
import './autoload/ada-compliance.js'; import './autoload/foundation.js'; // eslint-disable-line

// Import local dependencies
import './plugins/lazyload';
import './plugins/modernizr.min';
import 'slick-carousel';
import 'jquery-match-height';

import objectFitImages from 'object-fit-images';
import '@fancyapps/fancybox/dist/jquery.fancybox.min';
// import { jarallax, jarallaxElement } from 'jarallax';
import ScrollOut from 'scroll-out'; // eslint-disable-line
import 'fullpage.js/dist/fullpage.min';

/**
 * Import scripts from Custom Divi blocks
 */
// eslint-disable-next-line import/no-unresolved
//import '../blocks/divi/example-block/index.js';

/**
 * Import scripts from Custom Elementor widgets
 */
// eslint-disable-next-line import/no-unresolved
//import '../blocks/elementor/example-widget/index.js';

/**
 * Import scripts from Custom ACF Gutenberg blocks
 */
// eslint-disable-next-line import/no-unresolved
//import '../blocks/gutenberg/acf-example-block/index.js';

/**
 * Init foundation
 */
$(document).foundation();

/**
 * Fit slide video background to video holder
 */
function resizeVideo() {
  let $holder = $('.videoHolder');
  $holder.each(function () {
    let $that = $(this);
    let ratio = $that.data('ratio') ? $that.data('ratio') : '16:9';
    let width = parseFloat(ratio.split(':')[0]);
    let height = parseFloat(ratio.split(':')[1]);
    $that.find('.video').each(function () {
      if ($that.width() / width > $that.height() / height) {
        $(this).css({
          width: '100%',
          height: 'auto',
        });
      } else {
        $(this).css({
          width: ($that.height() * width) / height,
          height: '100%',
        });
      }
    });
  });
}

/**
 * Scripts which runs after DOM load
 */
$(document).on('ready', function () {
  if ($('#wpadminbar').length) {
    const adminBarHeight = $('#wpadminbar').height();

    $('.header').css('margin-top', '32px');
    $('.full-menu').css('height', `calc(100vh - ${adminBarHeight}px`);
    $('#fullpage-home-js').css('height', `calc(100vh - ${adminBarHeight}px`);
    $('.scroll-down').css('transform', 'translateY(-32px)');
    $('.overview__logo').css({
      transform: 'translateY(-32px)',
      '-webkit-transform': 'translateY(-32px)',
    });
  }

  /**
   * Full Menu
   */
  //Open
  $('.menu-js').on('click', function () {
    $('.full-menu').fadeIn();
    setTimeout(() => {
      $('.header-menu .menu-item').each(function (index) {
        const menuItem = $(this);

        $('.full-menu__logo').addClass('show');
        $('.full-menu .stay-tuned').addClass('show');
        $('.full-menu__footer').addClass('show');

        setTimeout(() => {
          menuItem.addClass('show');
        }, index * 100);
      });
    }, 200);
  });

  //Close
  $('.full-menu__close').on('click', function () {
    $('.header-menu .menu-item').each(function (index) {
      const menuItem = $(this);

      setTimeout(() => {
        menuItem.removeClass('show');
      }, index * 100);
    });

    setTimeout(() => {
      $('.full-menu__logo').removeClass('show');
      $('.full-menu .stay-tuned').removeClass('show');
      $('.full-menu__footer').removeClass('show');
      $('.full-menu').fadeOut();
    }, 500);
  });

  /**
   * Full Page Home Page
   */

  const scrollingSpeed = 1400;
  const renderDelay = scrollingSpeed * 0.2;

  if ($('body').hasClass('page-template-template-home') == true) {
    $('#fullpage-home-js').fullpage({
      licenseKey: 'gplv3-license',
      sectionSelector: '.fullpage-section',
      navigation: true,
      slidesNavigation: true,
      scrollOverflow: true,
      scrollingSpeed: scrollingSpeed,
      navigationTooltips: [
        'overview',
        'availability',
        'amenities',
        'residences',
      ],
      showActiveTooltip: true,
      responsiveWidth: 640,
      anchors: ['overview', 'availability', 'amenities', 'residences'],
      css3: true,
      afterLoad: function (origin, destination) {
        let section = destination.item;
        $(section).find('[data-scroll]').attr('data-scroll', 'in');
      },
      onLeave: function (origin, destination, direction) {
        const header = $('.header');

        if (destination.anchor !== 'overview') {
          header.addClass('small');
        } else {
          header.removeClass('small');
        }

        const fpNav = $('#fp-nav');
        if (destination.anchor === 'amenities') {
          fpNav.addClass('nav-blue');
        } else {
          fpNav.removeClass('nav-blue');
        }
        if (direction == 'up') {
          setTimeout(() => {
            let section = destination.item;
            $(section).find('[data-scroll]').attr('data-scroll', 'in');
          }, renderDelay);

          setTimeout(() => {
            let sectionBefore = origin.item;
            $(sectionBefore).find('[data-scroll]').attr('data-scroll', '');
          }, 1000);
        }
      },
    });
  }

  /**
   * Full Page Other Page
   */
  if ($('body').hasClass('page-template-template-other') == true) {
    $('#fullpage-other-js').fullpage({
      licenseKey: 'gplv3-license',
      sectionSelector: '.fullpage-other-section',
      navigation: false,
      slidesNavigation: true,
      scrollOverflow: true,
      scrollingSpeed: scrollingSpeed,
      navigationTooltips: ['hero', 'second', 'third', 'banner'],
      showActiveTooltip: false,
      responsiveWidth: 640,
      anchors: ['hero', 'second', 'third', 'banner'],
      css3: true,
      afterLoad: function (origin, destination) {
        let section = destination.item;
        $(section).find('[data-scroll]').attr('data-scroll', 'in');
      },
      onLeave: function (origin, destination, direction) {
        const header = $('.header');

        if (destination.anchor !== 'hero') {
          header.addClass('small');
        } else {
          header.removeClass('small');
        }

        if (direction == 'up') {
          setTimeout(() => {
            let section = destination.item;
            $(section).find('[data-scroll]').attr('data-scroll', 'in');
          }, renderDelay);

          setTimeout(() => {
            let sectionBefore = origin.item;
            $(sectionBefore).find('[data-scroll]').attr('data-scroll', '');
          }, 1000);
        }
      },
    });

    $('.second-section__slider').slick({
      arrows: false,
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      fade: true,
      cssEase: 'linear',
      autoplay: true,
      verticalSwiping: true,
      autoplaySpeed: 5000,
      pauseOnFocus: false,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            verticalSwiping: false,
          },
        },
      ],
    });

    $('.third-section__slider').slick({
      arrows: false,
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      fade: true,
      cssEase: 'linear',
      autoplay: true,
      verticalSwiping: true,
      autoplaySpeed: 5000,
      pauseOnFocus: false,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            verticalSwiping: false,
          },
        },
      ],
    });
  }

  /**
   * Full Page Availability Page
   */

  if ($('body').hasClass('page-template-template-availability') == true) {
    $('#fullpage-availability-js').fullpage({
      licenseKey: 'gplv3-license',
      sectionSelector: '.fullpage-availability-section',
      navigation: false,
      slidesNavigation: true,
      scrollOverflow: true,
      scrollingSpeed: scrollingSpeed,
      navigationTooltips: ['hero', 'main'],
      showActiveTooltip: false,
      responsiveWidth: 640,
      anchors: ['hero', 'main'],
      css3: true,
      afterLoad: function (origin, destination) {
        let section = destination.item;
        $(section).find('[data-scroll]').attr('data-scroll', 'in');
      },
      onLeave: function (origin, destination, direction) {
        const header = $('.header');

        if (destination.anchor !== 'hero') {
          header.addClass('small');
        } else {
          header.removeClass('small');
        }

        if (direction == 'up') {
          setTimeout(() => {
            let section = destination.item;
            $(section).find('[data-scroll]').attr('data-scroll', 'in');
          }, renderDelay);

          if (destination.anchor !== 'footer') {
            setTimeout(() => {
              let sectionBefore = origin.item;
              $(sectionBefore).find('[data-scroll]').attr('data-scroll', '');
            }, 1000);
          }
        }
      },
    });
  }

  /**
   * Full Page Neighborhood Page
   */

  if ($('body').hasClass('page-template-template-neighborhood') == true) {
    $('#fullpage-neighborhood-js').fullpage({
      licenseKey: 'gplv3-license',
      sectionSelector: '.fullpage-neighborhood-section',
      navigation: false,
      slidesNavigation: true,
      scrollOverflow: true,
      scrollingSpeed: scrollingSpeed,
      navigationTooltips: ['hero', 'main'],
      showActiveTooltip: false,
      responsiveWidth: 640,
      anchors: ['hero', 'main'],
      css3: true,
      afterLoad: function (origin, destination) {
        let section = destination.item;
        $(section).find('[data-scroll]').attr('data-scroll', 'in');
      },
      onLeave: function (origin, destination, direction) {
        const header = $('.header');

        if (destination.anchor !== 'hero') {
          header.addClass('small');
        } else {
          header.removeClass('small');
        }

        if (direction == 'up') {
          setTimeout(() => {
            let section = destination.item;
            $(section).find('[data-scroll]').attr('data-scroll', 'in');
          }, renderDelay);

          if (destination.anchor !== 'footer') {
            setTimeout(() => {
              let sectionBefore = origin.item;
              $(sectionBefore).find('[data-scroll]').attr('data-scroll', '');
            }, 1000);
          }
        }
      },
    });
  }

  /**
   * Full Page Blog Page
   */

  if ($('body').hasClass('blog') == true) {
    $('#fullpage-news-js').fullpage({
      licenseKey: 'gplv3-license',
      sectionSelector: '.fullpage-news-section',
      slidesNavigation: true,
      scrollOverflow: true,
      scrollingSpeed: scrollingSpeed,
      responsiveWidth: 640,
      anchors: ['hero', 'main'],
      css3: true,
      afterLoad: function (origin, destination) {
        let section = destination.item;
        $(section).find('[data-scroll]').attr('data-scroll', 'in');
      },
      onLeave: function (origin, destination, direction) {
        const header = $('.header');

        if (destination.anchor !== 'hero') {
          header.addClass('small');
        } else {
          header.removeClass('small');
        }

        if (direction == 'up') {
          setTimeout(() => {
            let section = destination.item;
            $(section).find('[data-scroll]').attr('data-scroll', 'in');
          }, renderDelay);

          if (destination.anchor !== 'footer') {
            setTimeout(() => {
              let sectionBefore = origin.item;
              $(sectionBefore).find('[data-scroll]').attr('data-scroll', '');
            }, 1000);
          }
        }
      },
    });
  }

  /**
   * Full Page Contact Page
   */

  if ($('body').hasClass('page-template-template-contact') == true) {
    $('#fullpage-contact-js').fullpage({
      licenseKey: 'gplv3-license',
      sectionSelector: '.fullpage-contact-section',
      slidesNavigation: true,
      scrollOverflow: true,
      responsiveWidth: 640,
      scrollingSpeed: scrollingSpeed,
      anchors: ['hero', 'main'],
      css3: true,
      afterLoad: function (origin, destination) {
        let section = destination.item;
        $(section).find('[data-scroll]').attr('data-scroll', 'in');
      },
      onLeave: function (origin, destination, direction) {
        const header = $('.header');

        if (destination.anchor !== 'hero') {
          header.addClass('small');
        } else {
          header.removeClass('small');
        }

        if (direction == 'up') {
          setTimeout(() => {
            let section = destination.item;
            $(section).find('[data-scroll]').attr('data-scroll', 'in');
          }, renderDelay);

          setTimeout(() => {
            let sectionBefore = origin.item;
            $(sectionBefore).find('[data-scroll]').attr('data-scroll', '');
          }, 1000);
        }
      },
    });
  }

  /**
   * Scroll Out
   */
  ScrollOut({
    threshold: 0.1,
    onShown: function (el) {
      el.classList.remove('animated');

      void el.offsetWidth;

      el.classList.add('animated');
    },
  });

  /**
   * Filter maps
   */
  $(document).ready(function () {
    $('.filters-neig li a').click(function (event) {
      event.preventDefault();
      $(this).parent().addClass('current');
      $(this).parent().siblings().removeClass('current');
    });
  });

  /**
   * Safari logo padding
   */
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

  if (isSafari) {
    // Safari-specific padding
    $('.overview').css({
      padding: '160px 0 95px',
    });
  }

  /**
   * Make elements equal height
   */
  $('.matchHeight').matchHeight();

  /**
   * IE Object-fit cover polyfill
   */
  if ($('.of-cover').length) {
    objectFitImages('.of-cover');
  }

  /**
   * Add fancybox to images
   */
  $('.gallery-item')
    .find('a[href$="jpg"], a[href$="png"], a[href$="gif"]')
    .attr('rel', 'gallery')
    .attr('data-fancybox', 'gallery');
  $(
    '.fancybox, a[rel*="album"], a[href$="jpg"], a[href$="png"], a[href$="gif"]'
  ).fancybox({
    minHeight: 0,
    helpers: {
      overlay: {
        locked: false,
      },
    },
  });

  /**
   * Init parallax
   */
  // jarallaxElement();
  // jarallax(document.querySelectorAll('.jarallax'), {
  //   speed: 0.5,
  // });

  /**
   * Detect element appearance in viewport
   */
  // ScrollOut({
  //   offset: function() {
  //     return window.innerHeight - 200;
  //   },
  //   once: true,
  //   onShown: function(element) {
  //     if ($(element).is('.ease-order')) {
  //       $(element)
  //         .find('.ease-order__item')
  //         .each(function(i) {
  //           let $this = $(this);
  //           $(this).attr('data-scroll', '');
  //           window.setTimeout(function() {
  //             $this.attr('data-scroll', 'in');
  //           }, 300 * i);
  //         });
  //     }
  //   },
  // });

  /**
   * Remove placeholder on click
   */
  const removeFieldPlaceholder = () => {
    $('input, textarea').each((i, el) => {
      $(el)
        .data('holder', $(el).attr('placeholder'))
        .on('focusin', () => {
          $(el).attr('placeholder', '');
        })
        .on('focusout', () => {
          $(el).attr('placeholder', $(el).data('holder'));
        });
    });
  };

  removeFieldPlaceholder();

  $(document).on('gform_post_render', () => {
    removeFieldPlaceholder();
  });

  /**
   * Scroll to Gravity Form confirmation message after form submit
   */
  // $(document).on('gform_confirmation_loaded', function (event, formId) {
  //   let $target = $('#gform_confirmation_wrapper_' + formId);
  //   if ($target.length) {
  //     $('html, body').animate({ scrollTop: $target.offset().top - 50 }, 500);
  //     return false;
  //   }
  // });

  /**
   * Hide gravity forms required field message on data input
   */
  $('body').on(
    'change keyup',
    '.gfield input, .gfield textarea, .gfield select',
    function () {
      let $field = $(this).closest('.gfield');
      if ($field.hasClass('gfield_error') && $(this).val().length) {
        $field.find('.validation_message').hide();
      } else if ($field.hasClass('gfield_error') && !$(this).val().length) {
        $field.find('.validation_message').show();
      }
    }
  );

  /**
   * Add `is-active` class to menu-icon button on Responsive menu toggle
   * And remove it on breakpoint change
   */
  $(window)
    .on('toggled.zf.responsiveToggle', function () {
      $('.menu-icon').toggleClass('is-active');
    })
    .on('changed.zf.mediaquery', function () {
      $('.menu-icon').removeClass('is-active');
    });

  /**
   * Close responsive menu on orientation change
   */
  $(window).on('orientationchange', function () {
    setTimeout(function () {
      if ($('.menu-icon').hasClass('is-active') && window.innerWidth < 641) {
        $('[data-responsive-toggle="main-menu"]').foundation('toggleMenu');
      }
    }, 200);
  });

  resizeVideo();

  $('.availability-filters__item').on('click', function () {
    $('.availability-filters__item').removeClass('current');
    $(this).addClass('current');
    let bedroomsNumber = $(this).data('filter');
    $('.properties-wrap__item').data('bedrooms');
    // console.log($(this).data('filter'));
    $.ajax({
      url: ajax_object.ajax_url,
      type: 'POST',
      data: {
        action: 'properties_filtering',
        bedrooms_filter: bedroomsNumber,
      },
      beforeSend: function () {
        $('.properties-wrap').addClass('loading');
      },
      success: function (result) {
        $('.properties-wrap').empty();
        $('.properties-wrap').html(result);
      },
      error: function () {
        console.log('error');
      },
    });
  });
});

/**
 * Scripts which runs after all elements load
 */
$(window).on('load', function () {
  // jQuery code goes here

  let $preloader = $('.preloader');
  if ($preloader.length) {
    $preloader.addClass('preloader--hidden');
  }
});

/**
 * Scripts which runs at window resize
 */
$(window).on('resize', function () {
  // jQuery code goes here

  resizeVideo();
});

/**
 * Scripts which runs on scrolling
 */
$(window).on('scroll', function () {
  // jQuery code goes here
});
